.choices a {
  text-decoration: none;
  color: black;
  background-color: #f5f5f5;
  padding: 0.5rem 0.8rem;
  border-radius: 20px;
}
.choices a.active {
  background-color: #485ec4;
  color: white;
}

.sortable{
  cursor: pointer;
}